ul { list-style-type:katakana-iroha;}
html, body {
  margin: 0;	padding: 0; width:100%; height:100%;
  font-weight:bolder;
  font-family:Georgia, "Times New Roman", Times, serif; color:#000; }
body, td, th, input, textarea, select, a {	font-size: 16px; }
a, a:visited {	color: #000; font-weight:bold; border:0 none; outline:none;}
a:hover {	text-decoration: underline;}
a img {	border: none;}

.App { max-width:1200px; margin: auto; }

.cRow { position: relative; display: block; margin:40px 0;overflow: visible;clear: both; }
.cCol { display: inline; clear:none; margin:20px 15px; width:100%; }
.cItem { position:relative; display:block; clear:both; width:100%; color:#fff;
  padding:0; text-shadow:1px 1px .5px #ccc; }
.cBlock { position:relative; display:block; clear:both; width:100%; }
.cInfo { position:relative; width:100%; margin:0; padding:0; text-align:right; }
.cBg .treeBG {
  background: transparent url("/public/assets/tree-round-blk.png") no-repeat top right;
  width: 300px;
  height: 300px;
  position: absolute;
  opacity:.25;
  bottom:0;
  right:0;
}

.cTitle { width: 100%;text-align:right; margin:0; padding:0}
.cTitle a { text-decoration: none; font-weight: bold;}
.cContent { margin:0; padding:5px; }
.cContent img { cursor:pointer}
.cContent video { width:100%; display: block;
  margin: 0;
  padding: 0;}
.cCaption { position:relative; margin:0; padding:0 0 10px 10px; clear:none; text-align:right;
  font-family:Georgia, "Times New Roman", Times, serif; font-size:15px; font-weight:bold;
  text-shadow:1px 1px 1px #000;
}
.cCaption a { text-shadow:none}
.moreBtn { font-size:80%; cursor:pointer;}
.kpVideo { }
.backBtn { float:right; width:20px; height:20px; margin:0 10px 0 5px; border:1px solid #fff;}
.fonter { float:right; font-weight:bolder; font-size:20px; margin:0 10px 0 5px; vertical-align:baseline; padding:0; cursor:pointer; }
.cTitle { font-family:Georgia, "Times New Roman", Times, serif; font-size:20px; color:#000;
  font-weight:bold; letter-spacing:20px; }

@media all and (max-width: 600px) {
  .cTitle { letter-spacing:4px; }
}

.videoObj { width:100%; }

dl { font-size: 14px }
dt { font-weight: 800 }

.imgList img { max-height: 200px; margin:3px 6px; }
.imgList.large img { max-height: 400px; margin:3px 6px; }

#caregiving sup { font-size:10px }
